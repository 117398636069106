
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

//window.Vue = require('vue');
window.iziToast = require('izitoast');
window.numeral = require('numeral');
require('numeral/locales/ja');
window.numeral.locale('ja');
window.moment = require('moment');
window.moment.locale('ja');

try {
    $.fn.iziModal = require('izimodal/js/iziModal.js');
} catch (e) {}

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

//Vue.component('example-component', require('./components/ExampleComponent.vue'));

// const files = require.context('./', true, /\.vue$/i)

// files.keys().map(key => {
//     return Vue.component(_.last(key.split('/')).split('.')[0], files(key))
// })

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app'
// });

/**
 * hexカラーコード定義をrgbaに変換する.
 * @param hex
 * @param alpha
 * @param defaultRgba
 * @returns {*}
 */
window.hex2rgba = function hex2rgba (hex, alpha = 1, defaultRgba = '') {

    if(hex === null){
        return defaultRgba;
    }

    // ロングバージョンの場合（例：#FF0000）
    let r = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    let c = null;
    if (r) {
        c = r.slice(1,4).map(function(x) { return parseInt(x, 16) })
    }
    // ショートバージョンの場合（例：#F00）
    r = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (r) {
        c = r.slice(1,4).map(function(x) { return 0x11 * parseInt(x, 16) })
    }
    // 該当しない場合は、nullを返す.
    if (!c) {
        return defaultRgba;
    }
    return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${alpha})`
};

/**
 * Randomなrgbaコードを返します。
 * @returns {string}
 */
window.getRandomRgba = function getRandomRgba(){
    let clr = 'rgba(';
    for(let i=0; i < 3; i++){
        clr = clr + Math.floor( Math.random()*255) + ',';
    }
    clr = clr + Math.floor( Math.random()*10)/10 + ')';
    return clr;
};
